import React from 'react';
import './WalmartMap.css';
import WelspunMapImage from '../../assets/welspun_map.png';
import PackShipImageIcon from '../../assets/pack_ship_icon.png';
import RetailImageIcon from '../../assets/retail_icon.png';
import RetailBlackImageIcon from '../../assets/retail_black_icon.png';
import WalmartBackgroundImage from '../../assets/Home.jpg';
import WelspunFinalIcon from '../../assets/welspun_final.png'

import { Grid } from '@mui/material';

const WalmartMap = () => {
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-align-center">
                    <img src={WelspunMapImage} alt="WelspunMapImage" className="welspun-map-image"/>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="pack-ship-left-container">
                    </Grid>
                    <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="pack-ship-right-container">
                        <img src={PackShipImageIcon} alt="PackShipImageIcon" className="pack-ship-image-icon"/>
                        <div className="pack-ship-desc">Welspun India to Welspun USA</div>
                        {/* <div className="pack-ship-date">Date of Ginning</div>
                        <div className="pack-ship-month-year">July 2021</div> */}
                    </Grid>
                    
                    <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="retail-left-container">
                        <img src={RetailImageIcon} alt="RetailImageIcon" className="retail-image-icon" />
                        <div className="retail-desc-container">
                            <div className="retail-empty-div"></div>
                            {/* <div className="retail-desc">Walmart Warehouse to Retail Store</div> */}
                        </div>
                    </Grid>
                    <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="retail-right-container">
                    </Grid>

                    <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="retail-black-left-container">
                    </Grid>
                    <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="retail-black-right-container">
                        <img src={RetailBlackImageIcon} alt="RetailBlackImageIcon" className="retail-black-icon" />
                        {/* <div className="retail-black-date">Date of Ginning</div>
                        <div className="retail-black-month-year">July 2021</div> */}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="text-align-center">
                    <img src={WalmartBackgroundImage} alt="WalmartBackgroundImage" className="walmart-background-image"/>
                </Grid>

                <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6}>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="question-right-container">
                    {/* <div className="walmart-question">Walmart Near you ?</div> */}
                    <img src={WelspunFinalIcon} alt="WelspunFinalIcon" className="welspun-final-icon"/>
                </Grid>
            </Grid>
        </>
    )
}

export default WalmartMap; 