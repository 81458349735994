import React from 'react';
import './Authenticity.css';
import AssociationImage from '../../assets/association.png';
import AssociationImageIcon from '../../assets/association_icon_farm.png';
import AssociationFarmImage from '../../assets/Authentically egyptian.jfif';
import FlagImage from '../../assets/flag.png';
import MapImage from '../../assets/map.png';

import { Grid } from '@mui/material';

const Authenticity = () => {
    return (
        <>
            <Grid container spacing={0} className="authenticity-container">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="authenticity-header text-align-center">
                        Authentically Egyptian
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="authenticity-description text-align-center">
                        Epitomizing comfort and luxury, our range of Egyptian cotton stands as one of the finest in the world. As the first organization to be accredited the Egyptian Golden Seal, our commitment has been towards sourcing only the most authentic Egyptian Cotton grown only by accredited cotton growers in Egypt
                    </div>
                </Grid>
                <Grid item xs={6.65} sm={6.65} md={7} lg={7} xl={7} className="authenticity-left-container">
                    <img src={AssociationImage} alt="AssociationImage" className="authenticity-image"/>
                </Grid>
                <Grid item xs={5.35} sm={5.35} md={5} lg={5} xl={5} className="authenticity-right-container">
                    <img src={AssociationImageIcon} alt="AssociationImageIcon" className="authenticity-image-icon"/>
                    {/* <div className="authenticity-lot-no">#Lot No: 34556, MM /YYYY</div> */}
                </Grid>
            </Grid>
            <Grid container spacing={0} className="map-image-container">
                <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6}>
                    <img src={AssociationFarmImage} alt="AssociationFarmImage" className="authenticity-farm-image"/>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="flag-map-image-container">
                    <img src={FlagImage} alt="FlagImage" className="flag-image" />
                    <img src={MapImage} alt="MapImage" className="map-image" />
                </Grid>
            </Grid>
        </>
    )
}

export default Authenticity;