import React from 'react';
import './Home.css';
import WelspumRoundedIcon from '../../assets/welspum.png';

import { Grid } from '@mui/material';

const Home = () => {

    return (
        <Grid  id="home" container spacing={0} className="home-container">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="tag-line text-align-center">
                    Sleep like a Pharaoh
                </div>
            </Grid>
            <Grid item xs={6.4} sm={6.4} md={6.4} lg={6.4} xl={6.4}>
            </Grid>
            <Grid item xs={5.6} sm={5.6} md={5.6} lg={5.6} xl={5.6} className="home-footer-container">
                <img src={WelspumRoundedIcon} alt="App Icon" className="app-icon-home" />
                <div className="foot-tag-line">Follow the Journey</div>
            </Grid>
        </Grid>
    )

}

export default Home;