import React from 'react';
import './SupplyChain.css';
import ChainImage from '../../assets/chain.png';
import GinningImageIcon from '../../assets/ginning.png';
import ChainFarmImage from '../../assets/chain_farm.jpg';
// import CertificateImage from '../../assets/certificate.png';

import { Grid } from '@mui/material';

const SupplyChain = () => {
    return (
        <>
            <Grid container spacing={0} className="chain-container">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="chain-header text-align-center">
                        Secure Supply Chain
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="chain-description text-align-center">
                        Our strategic partnership with Modern Nile has made it possible for us to track and trace the bales coming to us. Welspun sources around 10% of the world's Egyptian cotton, contributing to our expertise and know-how of the material.
                    </div>
                </Grid>
                <Grid item xs={6.6} sm={6.6} md={7} lg={7} xl={7} className="chain-left-container">
                    <img src={ChainImage} alt="ChainImage" className="chain-image"/>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5} lg={5} xl={5} className="chain-right-container">
                    <img src={GinningImageIcon} alt="GinningImageIcon" className="chain-image-icon"/>
                    <div className="chain-desc">Cotton Bales from Modern Nile Egypt</div>
                    {/* <div className="chain-date">Date of Ginning</div>
                    <div className="chain-month-year">July 2021</div> */}
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="chain-farm-certificate-container">
                    <img src={ChainFarmImage} alt="ChainFarmImage" className="chain-farm-image"/>
                    {/* <img src={CertificateImage} alt="CertificateImage" className="chain-certificate-image"/> */}
                </Grid>
            </Grid>
        </>
    )
}

export default SupplyChain; 