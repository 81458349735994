import React from 'react';
import './Comfort.css';
import SpinningImage from '../../assets/Spinning.jpg';
import SpinningImageIcon from '../../assets/spinning_icon.png';
import WeavingImage from '../../assets/Weaving.jpg';
import WeavingImageIcon from '../../assets/weaving_icon.png';
import FinishingImage from '../../assets/finishing.jpg';
import FinishingImageIcon from '../../assets/finishing_icon.png';

import { Grid } from '@mui/material';

const Comfort = () => {
    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="spin-left-container">
                    <img src={SpinningImage} alt="SpinningImage" className="spinning-image"/>
                    <div className="spinning-image-desc">Spun for comfort</div>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="spin-right-container">
                    <div className="spinning-desc">
                        Our bales reach India where they are spun into yarns and tested for purity.
                    </div>
                    <img src={SpinningImageIcon} alt="SpinningImageIcon" className="spinning-image-icon" />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="weaving-left-container">
                    <div className="weaving-desc">
                    The yarn is then woven into fabric, with each lot supervised and managed to avoid intermixing of different varietals.
                    </div>
                    <img src={WeavingImageIcon} alt="WeavingImageIcon" className="weaving-image-icon" />
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="weaving-right-container">
                    <img src={WeavingImage} alt="WeavingImage" className="weaving-image"/>
                    <div className="weaving-image-desc">Woven with care</div>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="finish-left-container">
                    <img src={FinishingImage} alt="FinishingImage" className="finish-image"/>
                    <div className="finish-image-desc">Dyed with love</div>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="finish-right-container">
                    <div className="finish-desc">
                        The cotton fabric is processed and dyed at our state of the art facilities.
                    </div>
                    <img src={FinishingImageIcon} alt="FinishingImageIcon" className="finish-image-icon" />
                </Grid>
            </Grid>
        </>
    )
}

export default Comfort;