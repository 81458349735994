import React from 'react';
import './CutSew.css';
import CutSewImage from '../../assets/cut_sew.png';
import CutSewImageIcon from '../../assets/cut_sew_icon.png';
import CutImage from '../../assets/cut.png';
// import CertificateImage from '../../assets/certificate.png';

import { Grid } from '@mui/material';

const CutSew = () => {
    return (
        <>
            <Grid container spacing={0} className="cutsew-container">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="cutsew-header text-align-center">
                        Tailored for you
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="cutsew-description text-align-center">
                        The fabric is cut and sewn to produce exquisite items that help make your house a HOME.
                    </div>
                </Grid>
                <Grid item xs={6.6} sm={6.6} md={7} lg={7} xl={7} className="cutsew-left-container">
                    <img src={CutSewImage} alt="ChainImage" className="cutsew-image"/>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5} lg={5} xl={5} className="cutsew-right-container">
                    <img src={CutSewImageIcon} alt="GinningImageIcon" className="cutsew-image-icon"/>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <img src={CutImage} alt="CutImage" className="cut-image"/>
                </Grid>
            </Grid>
        </>
    )
}

export default CutSew; 