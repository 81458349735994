import React from 'react';
import './Manufacturing.css';
import ManufacturingMapImage from '../../assets/manufacturing_map.png';
import ManufacturingIcon from '../../assets/manufacturing_icon.png';

import { Grid } from '@mui/material';

const Manufacturing = () => {
    return (
        <>
            <Grid container spacing={0} className="manufacturing-container">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="manufacturing-header text-align-center">
                        Meticulously Crafted
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="manufacturing-description text-align-center">
                        Our strategic partnership with Modern Nile has made it possible for us to track and trace the bales coming to us. Welspun sources around 10% of the world's Egyptian cotton, contributing to our expertise and know-how of the material.
                    </div>
                </Grid> */}
                <Grid item xs={6.65} sm={6.65} md={7} lg={7} xl={7} className="manufacturing-left-container">
                    <img src={ManufacturingMapImage} alt="ManufacturingMapImage" className="manufacturing-image"/>
                </Grid>
                <Grid item xs={5.35} sm={5.35} md={5} lg={5} xl={5} className="manufacturing-right-container">
                    <img src={ManufacturingIcon} alt="ManufacturingIcon" className="manufacturing-image-icon"/>
                    <div className="manufacturing-desc">Cotton Bales transferred to Welspun India</div>
                </Grid>
            </Grid>
        </>
    )
}

export default Manufacturing; 