import React from 'react';
import './CommittedQuality.css';
import CommittedQualityImage from '../../assets/committed_quality.png';
import CommittedQualityImageIcon from '../../assets/committed_quality_icon.png';
import FarmImage from '../../assets/farm.jpg';
// import CertificateImage from '../../assets/certificate.png';

import { Grid } from '@mui/material';

const CommittedQuality = () => {
    return (
        <>
            <Grid container spacing={0} className="quality-container">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="quality-header text-align-center">
                        Committed to Quality
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="quality-description text-align-center">
                    Chemical Fingerprinting by Oritain locates trace materials and helps us ensure that the origin of every bale is tested and proven to be 100% Egyptian Cotton.
                    </div>
                </Grid>
                <Grid item xs={6.6} sm={6.6} md={7} lg={7} xl={7} className="quality-left-container">
                    <img src={CommittedQualityImage} alt="CommittedQualityImage" className="quality-image"/>
                </Grid>
                <Grid item xs={5.4} sm={5.4} md={5} lg={5} xl={5} className="quality-right-container">
                    <img src={CommittedQualityImageIcon} alt="CommittedQualityImageIcon" className="quality-image-icon"/>
                    <div className="quality-desc">Chemical Fingerprinting of Origin by Oritain</div>
                    {/* <div className="quality-date">Date of Ginning</div>
                    <div className="quality-month-year">July 2021</div> */}
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="farm-certificate-container">
                    <img src={FarmImage} alt="FarmImage" className="farm-image"/>
                    {/* <img src={CertificateImage} alt="CertificateImage" className="certificate-image"/> */}
                </Grid>
            </Grid>
        </>
    )
}

export default CommittedQuality; 