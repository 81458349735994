import React from 'react';
import './Walmart.css';
import ClothesImage from '../../assets/clothes.png';
// import BlockchainImage from '../../assets/blockchain.png';
// import WalmartIcon from '../../assets/walmart.png';

import { Grid } from '@mui/material';

const Walmart = () => {

    return (
        <Grid container spacing={0} className="walmart-container">
            <Grid item xs={6.6} sm={6.6} md={6.6} lg={6.6} xl={6.6} className="walmart-left-container">
                <img src={ClothesImage} alt="ClothesImage" className="walmart-image"/>
                {/* <img src={BlockchainImage} alt="BlockchainImage" className="blockchain-image" /> */}
            </Grid>
            <Grid item xs={5.4} sm={5.4} md={5.4} lg={5.4} xl={5.4} className="walmart-right-container">
                {/* <img src={WalmartIcon} alt="WalmartIcon" className="walmart-icon" /> */}
                <div className="item-container">
                    <div className="walmart-item-header">Brand</div>
                    <div className="walmart-item-description">Hotel style</div>
                    <div className="walmart-item-description">600 Thread count</div>
                    <div className="walmart-item-description">Egyptian cotton sheet set</div>
                </div>
                <div className="item-container">
                    <div className="walmart-item-header">Fibre Content</div>
                    <div className="walmart-item-description">100% Egyptian cotton</div>
                    <div className="walmart-item-description">(excluding Flexifit panels)</div>
                </div>
                <div className="item-container">
                    <div className="walmart-item-header">Colors</div>
                    <div className="walmart-item-description">6 Solids, 2 Stripes</div>
                </div>
                <div className="item-container">
                    <div className="walmart-item-header">Sizes</div>
                    <div className="walmart-item-description">Full, Queen, King, Cal King</div>
                </div>
                <div className="item-container last-item">
                    <div className="walmart-item-header">Features</div>
                    <div className="walmart-item-description">Soft and Luxurious</div>
                    <div className="walmart-item-description">Flexifit technology provides</div>
                    <div className="walmart-item-description">the fitted sheet a snug fir</div>
                    <div className="walmart-item-description">throughout the night</div>
                </div>
            </Grid>
        </Grid>
    )

}

export default Walmart;