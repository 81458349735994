import './App.css';

import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Walmart from './components/Walmart/Walmart';
import Weltrak from './components/Weltrak/Weltrak';
import Authenticity from './components/Authenticity/Authenticity';
import CommittedQuality from './components/CommittedQuality/CommittedQuality';
import SupplyChain from './components/SupplyChain/SupplyChain';
import Manufacturing from './components/Manufacturing/Manufacturing';
import Comfort from './components/Comfort/Comfort';
import CutSew from './components/CutSew/CutSew';
import WalmartMap from './components/WalmartMap/WalmartMap';

const App = () => {
  return (
    <div className="App">
      <Header />
      <Home />
      <Walmart />
      <Weltrak />
      <Authenticity />
      <CommittedQuality />
      <SupplyChain />
      <Manufacturing />
      <Comfort />
      <CutSew />
      <WalmartMap />
    </div>
  );
}

export default App;
