import React from 'react';
import './Header.css';
import AppLogo from '../../assets/logoWhite.png';
// import AppIcon from '../../assets/app_icon.png';
import WELSPUN_LOGO_PRODUCT_IMAGE from '../../assets/WELSPUN_LOGO_PRODUCT.png'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { AppBar } from '@mui/material';

const Header = () => {

    return (
        <AppBar id="header-container" className="header-container" position="fixed" color="transparent">
            <div className="toolbar-header">
                <MenuRoundedIcon className="menu-icon"/>
                <a href="#home" className="logo-action-outer">
                    <img id="app-logo" src={AppLogo} alt="logo" className="app-logo-header empty-span"/>
                </a>
                <span className="empty-span">
                    <a href="#home" className="logo-action-inner">
                        <img id="app-logo-inner" src={AppLogo} alt="logo" className="app-logo-header empty-span"/>
                    </a>
                </span>
                <div className="menu-item">Authenticity</div>
                <div className="menu-item">Wel-trak™</div>
                <div className="menu-item">Welspun</div>
                <img id="app-icon" src={WELSPUN_LOGO_PRODUCT_IMAGE} alt="App Icon" className="app-icon-header" />
            </div>
        </AppBar>
    )

}

export default Header;