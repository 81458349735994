import React from 'react';
import './Weltrak.css';
import AppLogo from '../../assets/logo.png';

import { Grid } from '@mui/material';

const Weltrak = () => {
    return (
        <Grid container spacing={0} className="weltrak-container">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="weltrak-header text-align-center">About WEL-TRAK™</div>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} className="weltrak-left-container">
                <img src={AppLogo} alt="App logo" className="weltrak-logo"/>
            </Grid>
            <Grid item xs={7} sm={7} md={7} lg={7} xl={7} className="weltrak-right-container">
                A revolutionary patented tracking process that gives you a peace of mind knowing the home textiles you purchase meet the Quality, Safety, Ethical & Environmental values you demand.
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="weltrak-uid-no text-align-center">UID NO. 123456789</div>
            </Grid> */}
        </Grid>
    )
}

export default Weltrak;